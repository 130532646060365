<template>
  <ValidationObserver>
    <form @submit.prevent="saveData">
      <b-card title="Detail Request Order">
        <div class="form-group form-row">
          <label class="col-2 col-form-label">Name</label>
          <div class="col">
            <b-form-input
              v-model="formPayload.user_name"
              aria-readonly="true"
              required
              readonly
            />
          </div>
        </div>

        <div class="form-group form-row">
          <label class="col-2 col-form-label">Nomor Order</label>
          <div class="col">
            <b-form-input
              v-model="formPayload.number_order"
              aria-readonly="true"
              required
              readonly
            />
          </div>
        </div>

        <div class="form-group form-row">
          <label class="col-2 col-form-label">Nomor Telpon</label>
          <div class="col">
            <b-form-input
              v-model="formPayload.number_phone"
              aria-readonly="true"
              required
              readonly
            />
          </div>
        </div>

        <div class="form-group form-row">
          <label class="col-2 col-form-label">Permintaan Pekerjaan</label>
          <div class="col">
            <div
              class="p-1"
              style="background-color: #efefef; border-radius: 10px;"
            >
              <div
                v-if="formPayload.order_pekerjaan.length > 0"
                v-for="(data, index) in formPayload.order_pekerjaan"
              >
                {{ index + 1 }}.{{ data.name }}
              </div>
              <div v-else>
                -
              </div>
            </div>
          </div>
        </div>

        <div class="form-group form-row">
          <label class="col-2 col-form-label">Estimasi Luas</label>
          <div class="col">
            <b-form-input
              v-model="formPayload.estimation_luas"
              aria-readonly="true"
              required
              readonly
            />
          </div>
        </div>

        <div class="form-group form-row">
          <label class="col-2 col-form-label">Lokasi</label>
          <div class="col">
            <b-form-input
              v-model="formPayload.lokasi"
              aria-readonly="true"
              required
              readonly
            />
          </div>
        </div>

        <div class="form-group form-row">
          <label class="col-2 col-form-label">Description</label>
          <div class="col">
            <b-form-textarea
              v-model="formPayload.description"
              aria-readonly="true"
              rows="8"
              required
              readonly
            />
          </div>
        </div>

        <div class="form-group form-row">
          <label class="col-2 col-form-label">Tanggal Request</label>
          <div class="col">
            <b-form-input
              v-model="formPayload.tanggal_request"
              aria-readonly="true"
              required
              readonly
            />
          </div>
        </div>

        <div class="form-group form-row">
          <label class="col-2 col-form-label">File</label>
          <div class="col">
            <a :href="formPayload.file" target="_blank" class="btn btn-dark">Download Dokumen</a>
          </div>
        </div>

        <div class="form-group form-row">
          <label class="col-2 col-form-label">Status</label>
          <div class="col">
            <b-form-input
              v-model="formPayload.status"
              aria-readonly="true"
              required
              readonly
            />
          </div>
        </div>
      </b-card>
      <b-col cols="12" class="p-0 mt-2">
        <a
          @click="handleBackButton()"
          variant="secondary"
          class="btn waves-effect waves-float waves-light btn-secondary"
        >
          Back
        </a>
      </b-col>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormFile,
  BButton,
  BSpinner,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { VueEditor } from "vue2-editor";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { successNotification, errorNotification } from "@/auth/utils";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"],
  },
});
export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormFile,
    BFormInput,
    BFormTextarea,
    VueEditor,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  data() {
    return {
      category: [],
      subcategory: [],
      required,
      isLoading: false,
      formPayload: {},
      currentIndex: 0,
    };
  },
  computed: {
    currentName() {
      return this.dataList[this.currentIndex].name;
    },
  },
  setup(props) {
    return {
      successNotification,
      errorNotification,
    };
  },

  watch: {
    "formPayload.category_uuid": function(value) {
      this.subcategoryName(value);
    },
  },

  created() {
    this.getData();
    // this.categoryName();
  },

  methods: {
    getData() {
      const params = this.$route.params.slug;
      this.$http
        .get("/admin/request-order/" + params)
        .then((response) => {
          this.formPayload = response.data.data;
          this.formPayload.estimation_luas =
            this.formPayload.estimation_luas + "m2";
          this.formPayload.lokasi =
            this.formPayload.province + " / " + this.formPayload.city;
        })
        .catch((error) => {});
    },
    handleBackButton() {
      history.back();
    },
    preparePayload() {
      const form = new FormData();
      for (const key in this.formPayload) {
        if (key != "prices") {
          form.append(key, this.formPayload[key]);
        }
      }
      if (this.formPayload.prices.length) {
        for (let index = 0; index < this.formPayload.prices.length; index++) {
          const element = this.formPayload.prices[index];
          for (const key in element) {
            if (Object.hasOwnProperty.call(element, key)) {
              const items = element[key];
              form.append(`prices[${index}][${key}]`, items);
            }
          }
        }
      }
      return form;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
